import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import React from 'react';

export const DocumentComponentsLoader = () => {
  return (
    <div className='document-components-loader'>
      <CircularProgress />
    </div>
  );
};
