import { DocumentComponent } from './DocumentComponent';
import React, { useEffect, useState } from 'react';
import { DocumentComponentsProps } from './DocumentComponents.types';
import { DocumentComponentMerge } from './DocumentComponentMerge';
import { DocumentComponentsLoader } from './DocumentComponentsLoader';
import { useAdjustComponentsMutation } from '../../../store/files/documents/documents.list.service';
import { useSnackbar } from 'notistack';
import { ErrorMessages } from '../../../services/errors.service.types';
import { v4 as uuidv4 } from 'uuid';
import { DocumentComponentType } from '../../../store/files/documents/documents.list.types';

export const DocumentComponentsList = ({
  components: componentsFetched,
}: DocumentComponentsProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [components, setComponents] = useState<DocumentComponentType[]>([]);

  useEffect(() => {
    setComponents(componentsFetched ?? []);
  }, [componentsFetched]);

  const [adjust] = useAdjustComponentsMutation();

  const handleClick = (id: string) => async () => {
    setLoading(true);
    try {
      const component = components?.find((component) => component.componentId === id);
      const component_idx = components?.findIndex((component) => component.componentId === id);
      const next_component = components?.[component_idx! + 1];
      if (component && next_component) {
        console.log(component);
        console.log(component_idx);
        const new_component: DocumentComponentType = {
          componentId: uuidv4(),
          componentContent: component.componentContent + next_component?.componentContent,
          componentStatus: 'relevant',
        };
        await adjust({
          id,
          body: [
            {
              actionType: 'add',
              payload: {
                componentId: new_component.componentId,
                componentContent: new_component.componentContent,
                position: component.componentId,
              },
            },
            {
              actionType: 'delete',
              payload: {
                componentId: component.componentId,
              },
            },
            {
              actionType: 'delete',
              payload: {
                componentId: next_component.componentId,
              },
            },
          ],
        }).unwrap();
        setComponents(
          components
            .map((component, index) => {
              if (component.componentId === id) {
                return new_component;
              } else {
                return component;
              }
            })
            .filter((component) => {
              return component.componentId !== next_component.componentId;
            })
        );
      }
    } catch (e) {
      enqueueSnackbar(ErrorMessages.ComponentMergeError, { variant: 'error' });
    }
    setLoading(false);
  };

  return (
    <div className='document-components-list'>
      {loading && <DocumentComponentsLoader />}

      {components?.map((component) => (
        <React.Fragment key={component.componentId}>
          <DocumentComponent component={component} />
          <DocumentComponentMerge onClick={handleClick(component.componentId)} />
        </React.Fragment>
      ))}
    </div>
  );
};
