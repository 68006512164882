import React, { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';
import {
  DOCUMENT_CONTEXT_MENU_ITEM_NAME,
  DocumentContextMenuItemProps,
} from './DocumentContextMenu.types';
import { isDocumentResponse } from '../../../store/files/documentsAndClauses/list.helpers';
import {
  useGenerateComponentsMutation,
  useGetComponentsQuery,
} from '../../../store/files/documents/documents.list.service';
import { ConfirmationAgreementModal } from '../../StaticComponents/Modals/ConfirmationModal/ConfirmationAgreementModal';
import WarningIcon from '@mui/icons-material/Warning';
import { useSnackbar } from 'notistack';
import { ErrorMessages } from '../../../services/errors.service.types';

export const DocumentContextMenuSegmentation = ({
  document,
  onClose,
  disabled,
  onItemClick,
}: DocumentContextMenuItemProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const documentId = isDocumentResponse(document) ? document.DocumentId : '';
  const { error } = useGetComponentsQuery(documentId, {
    skip: !documentId,
  });

  const [generate] = useGenerateComponentsMutation();

  const handleMenuItemClick = () => {
    setShowModal(true);
  };

  const handleConfirm = async () => {
    setLoading(true);
    if (isDocumentResponse(document)) {
      try {
        await generate(documentId).unwrap();
        onItemClick?.(DOCUMENT_CONTEXT_MENU_ITEM_NAME.SEGMENTATION);
        onClose();
      } catch (e) {
        enqueueSnackbar(ErrorMessages.SegmentationGenerationError, { variant: 'error' });
      }
      setLoading(false);
    }
  };

  if (!error || (error && 'status' in error && error.status !== 404)) return null;
  return (
    <>
      <MenuItem disabled={disabled} onClick={handleMenuItemClick}>
        <LayersOutlinedIcon />
        Segmentation
      </MenuItem>

      <ConfirmationAgreementModal
        className='chat-agreement-modal'
        onClose={() => {
          onClose();
        }}
        onConfirm={() => {
          handleConfirm();
        }}
        message={
          <div className='chat-agreement-modal__terms_and_cond'>
            <p>
              <span>
                <WarningIcon className='warning-icon' />
                Read before you proceed with segmentation!
              </span>
            </p>
            <span>1. Lorem ipsum sit dolor amet blah blah.</span>
            <span>2. Lorem ipsum sit dolor amet blah blah.</span>
            <span>3. Lorem ipsum sit dolor amet blah blah.</span>
            <span>4. Lorem ipsum sit dolor amet blah blah.</span>
            <span>5. Lorem ipsum sit dolor amet blah blah.</span>
            <span>6. Lorem ipsum sit dolor amet blah blah.</span>
          </div>
        }
        label='I hearby confirm I have read and understood the statement.'
        open={showModal}
        successButtonLabel='Confirm'
        title='Segmentation warning'
        cancelButtonLabel='Cancel'
        loading={loading}
      />
    </>
  );
};
