import React, { useState } from 'react';
import { PanelCard } from '../PanelCard/PanelCard';
import { ReactComponent as CreateUserIcon } from '../../../assets/icons/create_user.svg';
import { ReactComponent as UserIcon } from '../../../assets/icons/user.svg';
import { ReactComponent as ReportIcon } from '../../../assets/icons/report.svg';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import { PanelSettingsListProps } from './PanelSettingsList.types';

import './PanelSettingsList.scss';
import { GenerateReport } from '../Report/GenerateReport';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { UploadMetadataCSV } from '../UploadMetadataCSV/UploadMetadataCSV';

export const PanelSettingsList: React.FC<PanelSettingsListProps> = ({
  handleClick,
  selectedCard,
}) => {
  const [renderGenerateReportModal, setRenderGenerateReportModal] = useState<boolean>(false);
  const [renderUploadMetadataCSVModal, setRenderUploadMetadataCSVModal] = useState<boolean>(false);

  return (
    <div className='panel-settings-list'>
      <PanelCard
        title='Create user'
        handleClick={() => handleClick(0)}
        icon={<CreateUserIcon />}
        selected={selectedCard === 0}
      />
      <PanelCard
        title='Manage users'
        handleClick={() => handleClick(1)}
        icon={<UserIcon />}
        selected={selectedCard === 1}
      />
      <PanelCard
        title='Tags management'
        handleClick={() => handleClick(2)}
        icon={<LocalOfferOutlinedIcon />}
        selected={selectedCard === 2}
      />
      <PanelCard
        title='Generate report'
        handleClick={() => setRenderGenerateReportModal(true)}
        icon={<ReportIcon />}
      />
      {renderGenerateReportModal && (
        <GenerateReport onClose={() => setRenderGenerateReportModal(false)} />
      )}
      <PanelCard
        title='Upload metadata file'
        handleClick={() => setRenderUploadMetadataCSVModal(true)}
        icon={<FileUploadOutlinedIcon />}
      />
      {renderUploadMetadataCSVModal && (
        <UploadMetadataCSV onClose={() => setRenderUploadMetadataCSVModal(false)} />
      )}
    </div>
  );
};
