import React from 'react';
import './DocumentComponents.scss';
import { DocumentComponentsLoading } from './DocumentComponentsLoading';
import { DocumentComponentsProps } from './DocumentComponents.types';
import { DocumentComponentsList } from './DocumentComponentsList';

export const DocumentComponentsViewer = ({ components }: DocumentComponentsProps) => {
  return (
    <div className='document-components-viewer'>
      {components?.length ? (
        <DocumentComponentsList components={components} />
      ) : (
        <DocumentComponentsLoading />
      )}
    </div>
  );
};
