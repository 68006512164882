import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CallMergeIcon from '@mui/icons-material/CallMerge';

export const DocumentComponentMerge = ({ onClick }: { onClick: () => void }) => {
  const [confirm, setConfirm] = useState(false);

  const handleClick = () => {
    if (confirm) {
      onClick();
      setConfirm(false);
    } else {
      setConfirm(true);
    }
  };

  const handleOnMouseLeave = () => {
    setConfirm(false);
  };

  return (
    <div className='document-component-merge'>
      <IconButton onClick={handleClick} onMouseLeave={handleOnMouseLeave}>
        {confirm ? (
          <>
            <WarningAmberIcon />
            <span>Are you sure to merge?</span>
          </>
        ) : (
          <CallMergeIcon />
        )}
      </IconButton>
    </div>
  );
};
