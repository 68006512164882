import { API_URL } from '../../../config/envs';
import { timeoutPromise } from '../../../helpers/app.helpers';
import { DocumentComponentsAdjustRequest } from './documents.list.types';

let component_id = '';
let component_count = 0;

type QueryType<T> = (id: T) => { url: string; method: string };

const log = (method: string, url: string, code: number) => {
  const color = method === 'GET' ? 'lightblue' : 'lightgreen';
  const colorCode = code < 400 ? 'green' : 'red';
  console.log(`%c${method} ${url}: %c${code}`, 'color: ' + color, 'color: ' + colorCode);
};

const components = (id: string) => [
  {
    componentId: 'id0',
    componentStatus: 'irrelevant',
    componentContent: 'document title ' + id,
  },
  {
    componentId: 'id1',
    componentStatus: 'relevant',
    componentContent:
      '12.1\nSie verlieren Ihren Versicherungsschutz, wenn Sie die\ngenannten Obliegenheiten vorsätzlich verletzen.',
  },
  {
    componentId: 'id2',
    componentStatus: 'relevant',
    componentContent:
      '12.2\nBei grober Fahrlässigkeit können wir die Leistung ent-\nsprechend der Schwere des Verschuldens kürzen.\nDies gilt nicht, wenn Sie nachweisen, dass Sie die\nObliegenheiten nicht grob fahrlässig verletzt haben.',
  },
  {
    componentId: 'id3',
    componentStatus: 'relevant',
    componentContent:
      '12.3\nVerletzen Sie eine nach Eintritt des Versicherungsfalls\nbestehende Auskunfts- und Aufklärungsobliegen-\nheit, kann der Versicherungsschutz vollständig oder\nteilweise entfallen. Voraussetzung hierfür ist, dass\nwir Sie mit einer gesonderten Mitteilung in Textform\nauf diese Folge hingewiesen haben.',
  },
  {
    componentId: 'id4',
    componentStatus: 'relevant',
    componentContent:
      '12.1\nSie verlieren Ihren Versicherungsschutz, wenn Sie die\ngenannten Obliegenheiten vorsätzlich verletzen.',
  },
  {
    componentId: 'id5',
    componentStatus: 'relevant',
    componentContent:
      '12.2\nBei grober Fahrlässigkeit können wir die Leistung ent-\nsprechend der Schwere des Verschuldens kürzen.\nDies gilt nicht, wenn Sie nachweisen, dass Sie die\nObliegenheiten nicht grob fahrlässig verletzt haben.',
  },
  {
    componentId: 'id6',
    componentStatus: 'relevant',
    componentContent:
      '12.3\nVerletzen Sie eine nach Eintritt des Versicherungsfalls\nbestehende Auskunfts- und Aufklärungsobliegen-\nheit, kann der Versicherungsschutz vollständig oder\nteilweise entfallen. Voraussetzung hierfür ist, dass\nwir Sie mit einer gesonderten Mitteilung in Textform\nauf diese Folge hingewiesen haben.',
  },
];

export const queryFnGet = (query: QueryType<string>) => {
  return (id: string) => {
    const { method, url: fullUrl } = query(id);
    const url = fullUrl.replace(API_URL, '');

    if (id === component_id) {
      if (component_count < 4) {
        component_count += 1;

        log(method, url, 202);
        return { status: 202, data: { timestamp: 'timestamp', components: [] } };
      }

      log(method, url, 200);
      return { status: 200, data: { timestamp: 'timestamp', components: components(id) } };
    }

    if (Math.random() > 0.3) {
      log(method, url, 404);
      return { error: { status: 404, data: undefined } };
    }

    log(method, url, 500);
    return { error: { status: 500, data: undefined } };
  };
};

export const queryFnGenerate = (query: QueryType<string>) => {
  return (id: string) => {
    const { method, url: fullUrl } = query(id);
    const url = fullUrl.replace(API_URL, '');

    if (Math.random() > 0.3) {
      component_id = id;
      component_count = 0;

      log(method, url, 200);
      return { status: 200, data: undefined };
    }

    log(method, url, 500);
    return { error: { status: 500, data: undefined } };
  };
};

export const queryFnAdjust = (query: QueryType<DocumentComponentsAdjustRequest>) => {
  return async (id: DocumentComponentsAdjustRequest) => {
    const { method, url: fullUrl, ...body } = query(id);
    console.log(body);
    const url = fullUrl.replace(API_URL, '');

    await timeoutPromise(2000);

    if (Math.random() > 0.3) {
      log(method, url, 200);
      return { status: 200, data: undefined };
    }

    log(method, url, 500);
    return { error: { status: 500, data: undefined } };
  };
};
