import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { MarsModal } from '../../StaticComponents/Modals/MarsModal';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { uiSelectors } from '../../../store/ui/ui.selectors';
import { closeValidateModal } from '../../../store/ui/ui.slice';
import { isClauseResponse } from '../../../store/files/documentsAndClauses/list.helpers';
import {
  BOOLEAN_VALUES,
  CustomTextField,
  requiredRule,
} from '../../UploadFiles/MetadataForm/MetadataFormHelpers';
import {
  MAX_REPORTING_REASON_COMMENT_LENGTH,
  REFRESH_DELAY_AFTER_OPEN_SEARCH_ACTION,
} from '../../../config/config';
import {
  clausesListApi,
  useApproveClauseMutation,
} from '../../../store/files/clauses/clauses.list.service';
import { TEST_ID } from '../../../config/test-fields-ids.config';
import { ErrorMessages } from '../../../services/errors.service.types';
import { useSnackbar } from 'notistack';
import {
  CLAUSES_LIST_API_TAGS,
  ClauseStatuses,
} from '../../../store/files/clauses/clauses.list.types';
import { timeoutPromise } from '../../../helpers/app.helpers';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { ClauseItem } from '../ClauseItem/ClauseItem';
import { handleCloseItemViewModal } from '../../../store/ui/ui.helpers';
import { ReportDetailsSummary } from '../../DocumentsAndClauses/ReportFile/ReportDetailsSummary';
import {
  ClauseStatusDictionary,
  StateDictionary,
  VALIDATE_FORM_LABELS,
  ValidateFormData,
} from './ValidateModal.types';
import { getDictionaryLabelByValue } from '../../../store/files/upload/list.helpers';
import './ValidateModal.scss';
import { useUserAccessContext } from '../../../contexts/UserAccess';

const inputProps = { maxLength: MAX_REPORTING_REASON_COMMENT_LENGTH };
const InputLabelProps = { shrink: true };

export const ValidateModal = () => {
  const { hasValidateModalAccess } = useUserAccessContext();
  const dispatch = useAppDispatch();
  const item = useAppSelector(uiSelectors.selectValidateModal);
  const [step, setStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const { handleSubmit, control, reset, getValues, watch } = useForm<ValidateFormData>({
    mode: 'onChange',
  });

  const { enqueueSnackbar } = useSnackbar();

  const [approveClause] = useApproveClauseMutation();

  const handleSubmitForm = async (data: ValidateFormData) => {
    setIsLoading(true);
    try {
      await approveClause({
        clauseId: item!.ClauseId,
        Approved: data.state,
        ApprovalComment: data.state_comment || undefined,
        ClauseStatus: data.status || undefined,
        Remarks: data.status_comment || undefined,
      }).unwrap();
      await timeoutPromise(REFRESH_DELAY_AFTER_OPEN_SEARCH_ACTION);
      dispatch(clausesListApi.util.invalidateTags([CLAUSES_LIST_API_TAGS.CLAUSES_LIST]));
      setStep(1);
    } catch (error) {
      enqueueSnackbar(ErrorMessages.ValidateError, { variant: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    reset();
    dispatch(closeValidateModal());
    setStep(0);
    handleCloseItemViewModal(dispatch, item);
  };

  if (!hasValidateModalAccess) return null;
  return (
    <MarsModal
      className='report-reason-modal validate-modal'
      open={!!item}
      onConfirm={step === 0 ? handleSubmit(handleSubmitForm) : handleClose}
      onClose={handleClose}
      buttonLabel={step === 0 ? 'Confirm' : 'Close'}
      loading={isLoading}
    >
      <div className='bookmarks-modal-title'>Validate clause</div>

      {step === 0 && (
        <>
          {isClauseResponse(item) && <ClauseItem clause={item} searchQuery='' />}
          <div>
            You can approve or decline this clause as well as change it’s current status. The
            information will be visible to other users and can be changed again at any point.
          </div>
          <form className='report-reason-form'>
            <div className='reason-dropdown'>
              <Controller
                name='state'
                control={control}
                rules={requiredRule(true, StateDictionary.required)}
                defaultValue=''
                render={({ field, fieldState: { error } }) => (
                  <CustomTextField
                    data-test-id={TEST_ID.VALIDATE_MODAL.STATE_INPUT}
                    {...field}
                    helperWarning={error}
                    select
                    label={VALIDATE_FORM_LABELS.STATE}
                    values={StateDictionary.values}
                  />
                )}
              />
            </div>

            {watch('state') === BOOLEAN_VALUES.FALSE && (
              <div className='comment-wording'>
                <Controller
                  name='state_comment'
                  control={control}
                  rules={requiredRule(true, true)}
                  defaultValue=''
                  render={({ field, fieldState: { error } }) => (
                    <CustomTextField
                      data-test-id={TEST_ID.VALIDATE_MODAL.STATE_COMMENT_INPUT}
                      inputProps={inputProps}
                      {...field}
                      multiline
                      helperWarning={error}
                      label=''
                      hasCounter
                      InputLabelProps={InputLabelProps}
                      placeholder={VALIDATE_FORM_LABELS.STATE_COMMENT}
                    />
                  )}
                />
              </div>
            )}

            {item?.ClauseStatus === ClauseStatuses.OTHER && (
              <div className='reason-dropdown'>
                <Controller
                  name='status'
                  control={control}
                  rules={requiredRule(true, ClauseStatusDictionary.required)}
                  defaultValue=''
                  render={({ field, fieldState: { error } }) => (
                    <CustomTextField
                      data-test-id={TEST_ID.VALIDATE_MODAL.STATUS_INPUT}
                      {...field}
                      helperWarning={error}
                      select
                      label={VALIDATE_FORM_LABELS.STATUS}
                      values={ClauseStatusDictionary.values}
                    />
                  )}
                />
              </div>
            )}

            {watch('status') && (
              <div className='comment-wording'>
                <Controller
                  name='status_comment'
                  control={control}
                  rules={requiredRule(true, true)}
                  defaultValue=''
                  render={({ field, fieldState: { error } }) => (
                    <CustomTextField
                      data-test-id={TEST_ID.VALIDATE_MODAL.STATUS_COMMENT_INPUT}
                      inputProps={inputProps}
                      {...field}
                      multiline
                      helperWarning={error}
                      label=''
                      hasCounter
                      InputLabelProps={InputLabelProps}
                      placeholder={VALIDATE_FORM_LABELS.STATUS_COMMENT}
                    />
                  )}
                />
              </div>
            )}
          </form>
        </>
      )}

      {step === 1 && (
        <div className='success-message'>
          <TaskAltIcon className='success-icon' />
          <div className='success-wording'>Clause validation was successfully submitted!</div>
          {isClauseResponse(item) && <ClauseItem clause={item} searchQuery='' />}
          <ReportDetailsSummary
            rows={[
              {
                label: VALIDATE_FORM_LABELS.STATE,
                value: getDictionaryLabelByValue(StateDictionary, getValues('state')),
              },
              { label: VALIDATE_FORM_LABELS.STATE_COMMENT, value: getValues('state_comment') },
              {
                label: VALIDATE_FORM_LABELS.STATUS,
                value: getDictionaryLabelByValue(ClauseStatusDictionary, getValues('status')),
              },
              {
                label: VALIDATE_FORM_LABELS.STATUS_COMMENT,
                value: getValues('status_comment'),
              },
            ]}
          />
        </div>
      )}
    </MarsModal>
  );
};
