import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';

export const DocumentComponentsLoading = () => {
  return (
    <div className='document-components-loading'>
      <CircularProgress />
      <strong>Pending...</strong>
      <div>
        Segmentation of the document can take up to 20 minutes.
        <br />
        Do not refresh or close your browser during the process.
      </div>
    </div>
  );
};
